import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Template from '../templates/about-template'

const About = (props) => <Template {...props} />

About.propTypes = {
  data: PropTypes.object.isRequired
}

export default About

export const pageQuery = graphql`
  query aboutFrQuery {
    settings: allContentfulSettings(
      filter: { node_locale: { eq: "fr-CA" } }
    ) {
      edges {
        node {
          ...Settings
        }
      }
    }
    about: allContentfulAbout(
      filter: { node_locale: { eq: "fr-CA" } }
    ) {
      edges {
        node {
          ...AboutPage
        }
      }
    }
  }
`
