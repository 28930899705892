import React, { useState } from 'react'
import { graphql } from 'gatsby'
import theme from '../helpers/theme'
import Layout from '../components/layout'
import Markdown from '../components/markdown'
import IMG from '../components/image'
import SEO from '../components/seo'
import Book from '../components/book'
import LinkOut from '../components/link-out'
import PopUp, { PopUpTimer } from '../components/popup'

const AboutTemplate = (props) => {

  const settings = props.data.settings.edges[0].node

  const {
    seoTitle,
    portrait,
    aboutCopy1,
    aboutCopy,
    press,
    contact,
		downloadForm
  } = props.data.about.edges[0].node

  // BOOK CONTENT
  const {
    // title,
    mainHeading,
    // mainDescription,
    // buyLink,
    // buyLinkText,
    // price,
    // relatedFeatures,
    buyLinks
  } = settings.featuredBook

  const [showPopup, setShowPopup] = useState(false)

  const handleClick = () => {
    setShowPopup(true)
  }

	// console.log(settings.newsletterForm)

  return (
    <Layout image={settings.navImageAbout} theme={theme.red} settings={settings} location={props.location}>
      <SEO title={seoTitle} />

      <PopUp showPopup={showPopup} setShowPopup={setShowPopup} copy={downloadForm} />
			{/* <PopUpTimer copy={settings.newsletterForm} /> */}

      <section className="has-lists innerx2 guttersx2 border-top">
        <h2>Age Of Union</h2>
        <Markdown field={aboutCopy1} className="md" />
      </section>

      <section className="has-lists innerx2 guttersx2">

        <div className="w33 prel z-5">
          <div className="event-poster fleft border-image">
            <picture>
              <IMG {...portrait} />
            </picture>
          </div>
        </div>

        <Markdown field={aboutCopy} className="md border-top" />
      </section>

      <section className="prel border-top inner guttersx2">
        <h2>{mainHeading}</h2>
        <div className="flex-center innerx2 gutters ready">
          <Book />
        </div>
        <div>
          <div className="cols--baseline outer flex-between">
            <div className="w75">
              <h3 className="regcase">Free eBook / Audiobook</h3>
            </div>
            <div className="span-7">
              <h4 className="button" onClick={handleClick}>Download</h4>
            </div>
          </div>
          {buyLinks && buyLinks.map((node, i) => (
            <div className="cols--baseline outer flex-between" key={`buylink-${i}`}>
              <div className="w75">
                <h3 className="regcase">{node.title}</h3>
              </div>
              <div className="span-7">
                {node.url ? (
                  <LinkOut href={node.url} className="h4 button">{node.label}</LinkOut>
                ) : (
                  <h4>{node.label}</h4>
                )}
              </div>
            </div>
          ))}
        </div>
      </section>

      <div className="flex">
        <section className="inner w50 mrx2 inline-flex border-top border-bottom">
          <div className="cols--about gutters-weak">
            <div className="span-6 gutters">
              <h2>Press</h2>
            </div>
            <div className="span-6 gutters">
              <Markdown field={press} className="md has-links" />
            </div>
          </div>
        </section>

        {!contact || (
          <section className="inner w50 inline-flex border-top border-bottom">
            <div className="cols--about gutters-weak">
              <div className="span-6 gutters">
                <h2>Contact</h2>
              </div>
              <div className="span-6 gutters">
                <Markdown field={contact} className="md has-links" />
              </div>
            </div>
          </section>
        )}
      </div>

    </Layout>
  )
}

export default AboutTemplate

export const aboutFragment = graphql`
  fragment AboutPage on ContentfulAbout {
    seoTitle
    portrait {
      fluid(maxWidth: 800, quality: 90) {
        srcSet,
        sizes,
        src,
        aspectRatio
      }
    }
    aboutCopy1 {
      childMarkdownRemark {
        html
      }
    }
    aboutCopy {
      childMarkdownRemark {
        html
      }
    }
    press {
      childMarkdownRemark {
        html
      }
    }
    contact {
      childMarkdownRemark {
        html
      }
    }
    featuredBook {
      title
      mainHeading
      mainDescription {
        childMarkdownRemark {
          html
        }
      }
      whereToBuy {
        childMarkdownRemark {
          html
        }
      }
      buyLinks {
        title
        label
        url
      }
      price
      buyLink
      buyLinkText
      relatedFeatures {
        ...FeatureLink
      }
    }
		downloadForm {
			...FormCopyFragment
		}
  }
`
